import React, { useState } from "react";
import "./App.css";

function App() {
  const [promptMessage, setPromptMessage] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [image, setImage] = useState("");
  const fetchImage = (e) => {
    e.preventDefault();
    setIsloading(true)
    const url = "https://stablediffusionapi.com/api/v3/text2img";
    const headers = {
      "Content-Type": "application/json", // Example header
      Authorization: "Bearer YOUR_ACCESS_TOKEN", // Example header
    };
    const data = {
      key1: "value1",
      key2: "value2",
    };

    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        key: "7hNpIqJwWQU5EtpdcxOIVy6i4e7MT4FKyx2AG09GUOffjU5glP6tLdrGk7PE",
        prompt: promptMessage,
        // negative_prompt:
        //   "((out of frame)), ((extra fingers)), mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), (((tiling))), ((naked)), ((tile)), ((fleshpile)), ((ugly)), (((abstract))), blurry, ((bad anatomy)), ((bad proportions)), ((extra limbs)), cloned face, (((skinny))), glitchy, ((extra breasts)), ((double torso)), ((extra arms)), ((extra hands)), ((mangled fingers)), ((missing breasts)), (missing lips), ((ugly face)), ((fat)), ((extra legs))",
        width: "512",
        height: "512",
        samples: "4",
        num_inference_steps: "20",
        safety_checker: "no",
        enhance_prompt: "yes",
        seed: null,
        guidance_scale: 7.5,
        webhook: null,
        track_id: null,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Request failed!");
        }
      })
      .then((responseData) => {
        console.log("Request successful!");
        setImage(responseData?.output);
        setPromptMessage('');
        setIsloading(false)
        console.log("Response:", responseData);
      })
      .catch((error) => {
        setIsloading(false);
        console.error("Request error:", error);
      });
  };

  return (
    <main className="  min-h-screen	">


      <div className="pb-[100px] min-h-screen flex items-center justify-center w-full">


        {image && !isLoading ? (<>

          <div className="grid grid-cols-2 m-4 h-[90%] ">

            {image.map((img) => (
              <img className="p-2 rounded" src={img} />
            ))}

          </div>
        </>

        ) : (<>
          {!isLoading ? <div className="m-auto min-h-screen flex items-center rounded text-white text-3xl">Prompt To Render Image</div> :
            <div role="status " className="m-auto flex min-h-screen	 justify-center items-center  rounded">
              <svg
                aria-hidden="true"
                class="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>}
        </>

        )}
      </div>

      <div className="fixed bottom-4 left-2 right-2 max-w-[800px] m-auto">
        <form onSubmit={fetchImage} className="">
          <label
            for="search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              onChange={(e) => setPromptMessage(e.target.value)}
              type="search"
              id="search"
              class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
              required
            />
            <button
              type="submit"
              class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Search
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}

export default App;
